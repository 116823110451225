import { React, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button"
import FormInput from "../components/Form/FormInput";
import Select from "../components/Form/Select";
import DateTime from "../components/Form/DateTime";
import Time from "../components/Form/Time"

import { Grade } from "../Data/Grade";
import { Session } from "../Data/Session";


const INITIAL_FORM_STATE = {
  studentsName: "",
  grade: "",
  email: "",
  sessionType: "",
  time: "",
  date: "",
};

const FORM_VALIDATION = Yup.object().shape({
  studentsName: Yup.string().required("Students Name  is Required"),
  grade: Yup.string().required("Grade is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  sessionType: Yup.string().required("Please Seelect Session Type"),
  time: Yup.string().required(
    "Please select what time you would like the session to be "
  ),
  date: Yup.date().required("Session date is required"),
});
const Book = () => {
  return (
    <div className='book'>
      <Box>
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={({ values }) => {
            console.log(values);
          }}
        >
          <Form>
            <Typography variant='h6' sx={{ margin: "1rem auto" }}>
              Book Session
            </Typography>

            <FormInput
              name='studentsName'
              label='Students Name'
              sx={{ margin: ".5rem auto" }}
            />

            <Select
              name='grade'
              label='Grade'
              options={Grade}
              sx={{ margin: "1rem auto" }}
            />

            <FormInput
              name='email'
              label='Email Address'
              sx={{ margin: ".5rem auto" }}
            />

            <Select
              name='sessionType'
              label='Session Type'
              options={Session}
              sx={{ margin: "1rem auto" }}
            />

            <DateTime
              name='date'
              label='Session Date'
              sx={{ margin: "1rem auto" }}
            />

            <Time
              name='time'
              label='Session Time'
              sx={{ margin: "1rem auto" }}
            />
            <Button>Submit</Button>
          </Form>
        </Formik>
      </Box>
    </div>
  );
};

export default Book;
