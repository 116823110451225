import React from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";

const Time = ({ name, ...otherProps }) => {
  const [field, meta] = useField(name);
  const configDateTime = {
    ...field,
    ...otherProps,
    type: "time",
    variant: "outlined",
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
    },
  };

  if (meta && meta.touched && meta.error) {
    configDateTime.error = true;
    configDateTime.helperText = meta.error;
  }
  return <TextField {...configDateTime} />;
};

export default Time;
